body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.iconfont {
  display: inline-block;
}

html, body {
  font-size: 16px;
}

.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}

.ml-30 {
  margin-left: 30px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.card-title {
  font-size: 20px;
}

.pointer {
  cursor: pointer;
}

.table-delete {
  color: #f5222d;
  
  &:hover {
    color: #cf1322;
  }
}

.table-primary {
  color: #1890ff;
  
  &:hover {
    color: #096dd9;
  }
}

.table-warning {
  color: #fa8c16;

  &:hover {
    color: #d46b08;
  }
}

.table-delete,
.table-warning,
.table-primary {
  margin-right: 20px;
  cursor: pointer;
}

.must {
  &::before {
    content: '*';
    color: red;
  }
}
