.log {
  .log-container {
    // background-color: #000;
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 8px;

    .log-row {
      font-size: 14px;
      margin: 0 0 3px;
    }
  }
}