.login {

  .login-box {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 300px;
    margin-top: -120px;
    margin-left: -200px;
    // border: 1px solid #ccc;
  }
}